*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Circe';
  font-weight: 100;
}

@font-face{font-family:circe;font-style:normal;font-weight:400;src:local('Circe'),url(https://fonts.cdnfonts.com/s/13503/CRC55.woff) format('woff')}@font-face{font-family:circe extra light;font-style:normal;font-weight:300;src:local('Circe Extra Light'),url(https://fonts.cdnfonts.com/s/13503/CRC25.woff) format('woff')}@font-face{font-family:circe light;font-style:normal;font-weight:350;src:local('Circe Light'),url(https://fonts.cdnfonts.com/s/13503/CRC35.woff) format('woff')}@font-face{font-family:circe bold;font-style:normal;font-weight:700;src:local('Circe Bold'),url(https://fonts.cdnfonts.com/s/13503/CRC65.woff) format('woff')}

.header-main {
  text-align: center;
  margin-bottom: 50px;
  padding-top: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ul-list {
  display: inline
}

.btn {
  width: 320px;
  height: 50px;
  border-radius: 4px;
  background-color: #444BFF;
  color: white;
  box-shadow: 4px 4px 0px #C5C7FF;
  margin-bottom: 48px;
  border: 0px;
  font-size: 20px
}

.link {
  display: block;
  width: 320px;
  height: 50px;
  border-radius: 4px;
  background-color: #444BFF;
  color: white;
  box-shadow: 4px 4px 0px #C5C7FF;
  border: 0px;
  text-align: center;
  font-size: 24px;
  line-height: 2;
  text-decoration: none;
}

.flex {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.btn:hover {
  background-color: #0107A6;
}

.main {
  background-color: black;
  height: 1000px;
  color: white;
}

.svg-filter {
  filter: invert(1%) sepia(50%) saturate(2) hue-rotate(86deg) brightness(169%) contrast(142%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
